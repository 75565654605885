import React, {useEffect, useState} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import MapContainer from "../components/GoogleMaps";
import {Helmet} from "react-helmet";
import Navigation from "../components/Layout/Navigation/Navigation";
import Footer from "../components/Layout/Footer";
import {Link} from "gatsby";
import '../../translation';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';

import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
const Contact = () => {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth'});
    }, [])

    const [errorForm, setErrorForm] = useState('');
    const [successForm, setSuccessForm] = useState('');

    const [captcha, setCaptcha] = useState(false);
    const [formValues, setFormValues] = useState({
        'nom' : {
          value: '',
        },
        'prenom' : {
            value: '',
        },
        'telephone' : {
            value: '',
        },
        'e-mail' : {
            value: '',
        },
        'demande' : {
            value: '',
        },
        'text' : {
            value: '',
        },
        'checked' : {
            value: '',
        },
    })

    const updateValue = (event, isSelect) => {
        setErrorForm('');
        setFormValues((prevState) => {
            return ({
                ...prevState,
                [event.target.name]: {
                    ...[event.target.name],
                    value: event.target.value
                }
            })
        })

    }

    const sendForm = () => {
        let error = false;
        if (captcha)
            Object.entries(formValues).map((object, key) => {
                if (!object[1].value || object[1].value === 'false') {
                    error = true;
                }
                return '';
            })
        else
            setErrorForm('Merci de renseigner tout les champs requis')
        if (error)
            setErrorForm('Merci de renseigner tout les champs requis')
        else {
            setSuccessForm("Envoi en cours...")
            emailjs.send('service_b2fmor7', 'template_a2k5d5k', {
                "to_email": 'collegelyceelafayette@gmail.com',
                'message': formValues.text.value,
                'from_nom': formValues.nom.value,
                'from_prenom': formValues.prenom.value,
                'from_tel': formValues.telephone.value,
                'from_demande': formValues.demande.value,
                'from_email': formValues["e-mail"].value,
                'from_site': 'Collège Lycée Lafayette'
            }, 'user_Lij0QMTOJtYDToKw8Nuqn')
                .then(() => {
                    setSuccessForm("Email envoyé avec succès.")
                }, () => {
                    setSuccessForm('');
                    setErrorForm("Une erreur s'est produite lors de l'envoi de votre email. Merci d'envoyer un email directement sur ecoleps2@gmail.com.")
                });
        }

    }
    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Contact | Collège Lycée Lafayette Nice</title>
                    <meta name="description" content="Le Collège Lycée Lafayette offre cursus bilingue et pluridisciplinaire : éducation bi-culturelle, une pédagogie différenciée, la confiance et l'estime de soi." />
                </Helmet>
                <Sidebar />
                <div className='content fadeInEarth'>
                    <div className='special-title'>Contact</div>
                    <div className='text'>
                       <p dangerouslySetInnerHTML={{__html: t('contact-text')}}>
                       </p><br />
                    </div>
                    <div  style={{display: 'block', position: 'relative', width: '100%', height: '350px'}}>
                        <MapContainer />
                    </div>
                   <br/><br/>
                    <div className='text'>
                        <p>
                            <strong>CIL<br />
                            Collège Lycée Lafayette</strong><br />
                            10, avenue Georges Clémenceau<br />
                            06000 Nice<br />
                            France
                        </p>
                        <div className='page-divider' />
                        <p><strong>{t('contact-prise-rdv')}&nbsp;</strong><a href="tel:0493620029"><strong>04 93 62 00 29</strong></a><strong>{t('contact-par-mail')}<a
                            href="mailto:collegelyceelafayette@gmail.com">collegelyceelafayette@gmail.com</a></strong></p>
                    </div>
                    <br />
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        sendForm();
                    }
                    }>
                        <div className="row">
                            <div className="col-12 col-md-6 mb-4">
                                <input
                                    type="text"
                                    required
                                    value={formValues.nom.value}
                                    className="form-control"
                                    placeholder={t('contact-plaholder1')}
                                    name='nom'
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-4">
                                <input
                                    type="text"
                                    required
                                    value={formValues.prenom.value}
                                    className="form-control"
                                    placeholder={t('contact-plaholder2')}
                                    name='prenom'
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-4">
                                <input
                                    type="text"
                                    required
                                    value={formValues.telephone.value}
                                    className="form-control "
                                    placeholder={t('contact-plaholder3')}
                                    name='telephone'
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-4">
                                <input
                                    type="text"
                                    required
                                    value={formValues["e-mail"].value}
                                    className="form-control"
                                    placeholder={t('contact-plaholder4')}
                                    name='e-mail'
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="col-12 mb-4">
                                <select
                                    onBlur={(e) => updateValue(e, true)}
                                    className="form-control"
                                    name='demande'
                                >
                                    <option defaultValue>{t('contact-plaholder5')}</option>
                                    <option>{t('contact-plaholder6')}</option>
                                    <option>{t('contact-plaholder7')}</option>
                                    <option>{t('contact-plaholder8')}</option>
                                    <option>{t('contact-plaholder9')}</option>
                                    <option>{t('contact-plaholder10')}</option>
                                </select>
                            </div>
                            <div className='col-12 mb-4'>
                                <textarea
                                    placeholder={t('contact-plaholder1')}
                                    className="form-control"
                                    minLength={50}
                                    value={formValues.text.value}
                                    name='text'
                                    onChange={updateValue}
                                    rows="4">
                                </textarea>
                            </div>
                            <div className='col-12 col-md-6 mb-4'>
                                <div className="pl-4 form-check">
                                    <input
                                        className="form-check-input"
                                        name='checked'
                                        type="checkbox"
                                        required
                                        onChange={updateValue}
                                        value={!formValues.checked.value}
                                    />
                                    {t('contact-policies')}<Link to="/protection-de-la-vie-privee" target="_blank">{t('contact-policies1')}</Link>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-4'>
                                <div>
                                    <GoogleReCaptchaProvider reCaptchaKey="6LeJ2dkaAAAAAMIC1FY6PuLDX3BizC220ePj-EXR">
                                        <GoogleReCaptcha onVerify={(val) => {console.log(val); setCaptcha(true)}} />
                                    </GoogleReCaptchaProvider>
                                </div>
                            </div>

                        </div>

                        <div className='submit text-center'>
                            {errorForm && <div className="alert alert-danger" role="alert">
                                {errorForm}
                            </div>}
                            {successForm && <div className="alert alert-success" role="alert">
                                {successForm}
                            </div>}
                            <button disabled={successForm} type='submit' className='btn btn-secondary btn-md btn-block'>
                                {t('contact-send')}
                            </button>
                        </div>
                    </form>

                </div>
            </div>
            <Footer />
        </>

    )
}

export default Contact;