import React, {Component} from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

export class MapContainer extends Component {
    render() {
        return (
            <Map
                initialCenter={{
                    lat: 43.70215663430551,
                    lng: 7.265103592846234
                }}
                google={this.props.google} zoom={11}>
                <Marker name={'Current location'}>

                </Marker>

            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyDcn1L4D_cwCgZlqSDZ3GaqnBa2nKVRJ4w')
})(MapContainer)